'use strict';
$(function () {
  $('#contactForm').submit(function (e) {
    e.preventDefault();
    var
      $form = $(this),
      data  = $form.serializeArray();
    data.push({name: '_type', value: 'Ajax'});
    $form.find('.alert').remove();
    $form.find(':input').prop('readonly', true);
    $form.find('button').prop('disabled', true);
    $('html').addClass('loading');
    $.ajax({
      url: $form.attr('action'),
      method: $form.attr('method'),
      data: data,
      cache: false,
      dataType: 'json',
      timeout: 30*1000,
      success: function(result) {
        console.log(data);
        console.log(result);
        var $message = $('<div>').addClass('alert alert-danger');
        if (result.errors && result.errors.length > 0)  {
          $message.html(result.errors.join("\n"));
        } else if (result.success) {
          $message.removeClass('alert-danger').addClass('alert-success').html(result.success);
          $form[0].reset();
        } else {
          $message.html('Unknown error [no_errors_data]');
        }
        $message.prependTo($form);
        $form.find(':input').prop('readonly', false);
        $form.find('button').prop('disabled', false);
        $('html').removeClass('loading');
      },
      error: function(xhr, options, error) {
        $('<div>').addClass('alert alert-danger').html('Unknown error [internal_error]').prependTo($form);
        $form.find(':input').prop('readonly', false);
        $form.find('button').prop('disabled', false);
        $('html').removeClass('loading');
        console.error(xhr.status);
        console.error(error);
      },
    });
    return false;
  });
});